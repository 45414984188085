import { Button, Flex, Group, LoadingOverlay, ScrollArea, getDefaultZIndex } from '@mantine/core'
import { parseISO } from 'date-fns'
import formatISO from 'date-fns/formatISO'
import React from 'react'

import {
  ConversationMessageAuthorEnum,
  ConversationTypeEnum,
  OrderByDirectionEnum,
  SubscriptionPlan,
  useCharacterEmbedQuery,
  useCharacterQuery,
  useConversationMessagesLazyQuery,
  useConversationQuery,
  useCreateConversationMutation,
  useCreateSurveyConversationMutation,
  useSendMessageMutation,
  useSurveyQuery,
} from '../../../generated/graphql'
import { CharacterSentimentSelect } from '../../components/Character'
import { useSubscriptionValidity } from '../../utils/SubscriptionValidity'
import { useAuth } from '../../utils/auth'
import {
  CharacterSentimentEnum,
  getParsedCharacterMetadata,
  getParsedSurveyMetadata,
} from '../../utils/character'
import { RefetchQueriesEnum } from '../../utils/constants'
import { useStorePublicConversation } from '../../utils/usePublicConversationsWithLocalStorage'
import { ChatInput } from './ChatInput'
import { ChatMessagesVirtualScroll, Message } from './ChatMessagesVirtualScroll'
import { ConversationIntro } from './ConversationIntro'

interface Props {
  topContent?: React.ReactNode
  conversationId?: string
  characterEmbedId?: string
  surveyId?: string
  userName?: string
  characterId?: string
  shouldRequestLogin?: boolean
  hasBookmark?: boolean
  isSmall?: boolean
  isReadOnly?: boolean
  canSelectSentiment?: boolean
}

const QUICK_MESSAGES = [
  {
    label: 'Get a pickup line',
    message:
      "Give me a cool classy engaging pickup line that she'll want to reply to. May be connected to her interests.",
  },
  {
    label: 'Ask her out',
    message: 'How should I ask her out?',
  },
  {
    label: 'Ask more about her hobbies',
    message: "How to ask her about her hobbies so she'll have to reply in engaging way.",
  },
]

export const Chat: React.FC<Props> = ({
  topContent,
  userName,
  characterId,
  characterEmbedId,
  surveyId,
  conversationId,
  shouldRequestLogin,
  hasBookmark,
  isSmall,
  isReadOnly = false,
  canSelectSentiment = false,
}) => {
  const {
    data: { User },
  } = useAuth()
  const [id, setId] = React.useState(conversationId)
  const [sentiment, setSentiment] = React.useState(CharacterSentimentEnum.neutral)
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null)
  const [createConversationMutation, createConversationMutationData] =
    useCreateConversationMutation()
  const [conversationMessagesLazyQuery, conversationMessagesLazyQueryData] =
    useConversationMessagesLazyQuery()
  const [sendMessageMutation, sendMessageMutationData] = useSendMessageMutation({
    refetchQueries: [RefetchQueriesEnum.Me, RefetchQueriesEnum.Conversation],
  })
  const [createSurveyConversationMutation, createSurveyConversationMutationData] =
    useCreateSurveyConversationMutation()
  const subscriptionValidity = useSubscriptionValidity()

  useStorePublicConversation({ id })

  const conversationQuery = useConversationQuery({
    variables: {
      id: id!,
    },
    skip: !id,
  })

  const characterEmbedQuery = useCharacterEmbedQuery({
    variables: {
      id: characterEmbedId!,
    },
    skip: !characterEmbedId,
  })

  const surveyQuery = useSurveyQuery({
    variables: {
      id: surveyId!,
    },
    skip: !surveyId,
  })

  const characterQuery = useCharacterQuery({
    variables: {
      id:
        characterEmbedQuery.data?.characterEmbed?.Character.id ??
        conversationQuery.data?.conversation?.Character.id ??
        surveyQuery.data?.survey?.Character.id ??
        characterId!,
    },
    skip:
      !characterId &&
      !conversationQuery.data?.conversation?.Character.id &&
      !characterEmbedQuery.data?.characterEmbed?.Character.id &&
      !surveyQuery.data?.survey?.Character.id,
    onCompleted: (data) => {
      const parsedMetadata = getParsedCharacterMetadata(data.character?.metaData ?? '')
      setSentiment(parsedMetadata.sentiment)
    },
  })

  const character = characterQuery.data?.character
  const characterEmbed = characterEmbedQuery.data?.characterEmbed
  const survey = surveyQuery.data?.survey
  const parsedSurveyMetadata = getParsedSurveyMetadata(survey?.metaData ?? '')

  const [messages, setMessages] = React.useState<Message[]>([])
  const loadConversationMessages = React.useCallback(
    async (dates: { dateGt?: string; dateLt?: string } = {}) => {
      if (!id) {
        return
      }
      const { data } = await conversationMessagesLazyQuery({
        variables: {
          filterData: {
            conversationId: id,
            ...dates,
          },
          pagination: {
            from: 0,
            length: 500,
            orderBy: 'createdAt',
            orderByDirection: OrderByDirectionEnum.Desc,
          },
        },
      })
      if (data) {
        setMessages((previousMessages) => {
          const newMessages = [
            ...(data.conversationMessages.conversationMessages as Array<
              Omit<Message, 'createdAt'> & { createdAt: string }
            >),
            ...previousMessages,
          ]
            .filter(
              (message, index, arr) =>
                !message.isPlaceholder && arr.findIndex(({ id }) => message.id === id) === index
            )
            .map(({ id, author, text, createdAt, isBookmarked }) => {
              let textMessage = text
              const devModeDelimiter = '(🔓Developer Mode Output)'
              if (textMessage.includes(devModeDelimiter)) {
                const unsaveText = textMessage.split(devModeDelimiter).slice(1)
                if (unsaveText.length > 0) {
                  textMessage = unsaveText.join('\n')
                }
              }
              return {
                id,
                author,
                isBookmarked,
                text: textMessage,
                createdAt: typeof createdAt === 'string' ? parseISO(createdAt) : createdAt,
              }
            })
            .sort((a, b) => {
              const diff = a.createdAt.getTime() - b.createdAt.getTime()
              if (diff === 0) {
                return 0
              }
              return diff < 0 ? -1 : 1
            })

          return newMessages
        })
      }
    },
    [id, conversationMessagesLazyQuery]
  )

  React.useEffect(() => {
    if (conversationId && conversationId !== id) {
      setId(conversationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId])

  const lastMessageId = messages[messages.length - 1]?.id
  React.useEffect(() => {
    const element = scrollContainerRef.current
    if (!element) {
      return
    }
    const timeout = window.setTimeout(() => {
      const { scrollHeight, clientHeight } = element
      if (scrollHeight && clientHeight) {
        scrollContainerRef.current?.scroll({
          top: scrollHeight - clientHeight,
          left: 0,
          behavior: 'smooth',
        })
      }
    }, 50)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [lastMessageId, character?.id])

  React.useEffect(() => {
    if (id) {
      setMessages([])
      void loadConversationMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const sendMessage = React.useCallback(
    async (message: string) => {
      if (
        !shouldRequestLogin &&
        !survey?.id &&
        !characterEmbed?.id &&
        !subscriptionValidity.validate({
          creditsNeeded: true,
          plansAllowed: [SubscriptionPlan.Basic, SubscriptionPlan.Premium, SubscriptionPlan.Pro],
        })
      ) {
        return
      }
      const lastDateCreatedAt = messages[messages.length - 1]?.createdAt
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: message,
          createdAt: new Date(),
          isPlaceholder: true,
          author: ConversationMessageAuthorEnum.User,
          id: 'sending',
          isBookmarked: false,
        },
        {
          text: 'Typing ...',
          isPlaceholder: true,
          createdAt: new Date(),
          author: ConversationMessageAuthorEnum.Ai,
          id: 'typing',
          isBookmarked: false,
        },
      ])

      if (id) {
        await sendMessageMutation({
          variables: {
            inputData: {
              conversationId: id,
              text: message,
            },
          },
        })

        void loadConversationMessages(
          lastDateCreatedAt ? { dateGt: formatISO(lastDateCreatedAt) } : undefined
        )
      } else if (characterId || characterEmbed?.id) {
        const { data } = await createConversationMutation({
          variables: {
            inputData: {
              userId: User.id,
              characterId: characterId ?? characterEmbed!.Character.id,
              characterEmbedId: characterEmbed?.id ?? null,
              initialMessage: message,
              metaData: JSON.stringify({
                sentiment,
              }),
            },
          },
        })
        const newId = data?.createConversation?.id
        if (newId) {
          setId(newId)
        }
      }
    },
    [
      id,
      sentiment,
      User.id,
      characterId,
      characterEmbed,
      shouldRequestLogin,
      survey,
      messages,
      createConversationMutation,
      loadConversationMessages,
      sendMessageMutation,
      subscriptionValidity,
    ]
  )

  const isFinished = !!conversationQuery.data?.conversation?.SurveySubmit?.isFinished
  const shouldShowLoginPrompt = !User.id && !!shouldRequestLogin && messages.length > 9

  return (
    <Flex sx={{ flex: 1 }} direction="column" h="100%" justify="space-between" pos="relative">
      {topContent}
      {messages.length === 0 &&
        (characterQuery.loading ||
          conversationQuery.loading ||
          surveyQuery.loading ||
          createSurveyConversationMutationData.loading ||
          conversationMessagesLazyQueryData.loading) && (
          <LoadingOverlay visible overlayBlur={2} zIndex={getDefaultZIndex('max')} />
        )}
      {character &&
        messages.length === 0 &&
        !(
          surveyQuery.loading ||
          createSurveyConversationMutationData.loading ||
          conversationQuery.loading ||
          conversationMessagesLazyQueryData.loading
        ) && (
          <ConversationIntro
            isSmall={isSmall}
            character={character}
            title={survey?.title}
            subTitle={parsedSurveyMetadata.subTitle}
            highlight={parsedSurveyMetadata.highlight}
          />
        )}
      {surveyId &&
        !messages.length &&
        !(
          surveyQuery.loading ||
          createSurveyConversationMutationData.loading ||
          conversationQuery.loading ||
          conversationMessagesLazyQueryData.loading
        ) && (
          <Group position="center" mt="lg">
            <Button
              onClick={async () => {
                const { data } = await createSurveyConversationMutation({
                  variables: {
                    inputData: {
                      surveyId,
                    },
                  },
                })

                if (data?.createSurveyConversation) {
                  setId(data.createSurveyConversation.id)
                }
              }}
            >
              Start survey
            </Button>
          </Group>
        )}
      <Flex
        ref={scrollContainerRef}
        sx={{ flexBasis: 0, overflow: 'auto', overscrollBehavior: 'contain', flexGrow: 1 }}
        h="100%"
        mr="-1rem"
        w="100"
      >
        {character ? (
          <ChatMessagesVirtualScroll
            userName={userName}
            messages={messages}
            characterId={character.id}
            characterAvatarUrl={character.ImageS3File?.thumbnailSignedUrl ?? null}
            characterName={character.title}
            hasBookmark={hasBookmark}
            shouldRequestLogin={shouldShowLoginPrompt}
            onMessageBookmark={(messageId) =>
              setMessages((prevMessages) => {
                const clonedPrevMessages = [...prevMessages]

                const messageIndex = clonedPrevMessages.findIndex(({ id }) => id === messageId)
                if (messageIndex !== -1) {
                  const message = clonedPrevMessages[messageIndex]
                  clonedPrevMessages[messageIndex] = {
                    ...message,
                    isBookmarked: !message.isBookmarked,
                  }
                }

                return clonedPrevMessages
              })
            }
          />
        ) : null}
      </Flex>
      {canSelectSentiment &&
        !id &&
        !(conversationQuery.loading || conversationMessagesLazyQueryData.loading) && (
          <CharacterSentimentSelect
            variant="filled"
            sentiment={sentiment}
            onSentimentChange={setSentiment}
          />
        )}

      {!isReadOnly &&
        (!surveyQuery.data?.survey?.id || conversationQuery.data?.conversation?.id) &&
        !isFinished && (
          <ChatInput
            isDisabled={shouldShowLoginPrompt}
            quickMessages={
              conversationQuery.data?.conversation?.type === ConversationTypeEnum.Dating ? (
                <ScrollArea w="100%" type="never">
                  <Group noWrap spacing="xs">
                    {QUICK_MESSAGES.map(({ label, message }) => (
                      <Button
                        key={label}
                        disabled={
                          sendMessageMutationData.loading || createConversationMutationData.loading
                        }
                        compact={false}
                        variant="light"
                        color="cyan"
                        radius="md"
                        size="xs"
                        onClick={() => {
                          void sendMessage(message)
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </Group>
                </ScrollArea>
              ) : undefined
            }
            isLoading={sendMessageMutationData.loading || createConversationMutationData.loading}
            onSendMessage={(message) => {
              void sendMessage(message)
            }}
          />
        )}
    </Flex>
  )
}
