import { Box } from '@mantine/core'
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { ConversationMessageAuthorEnum } from '../../../generated/graphql'
import { CommentHtml } from '../../components/CommentHtml'
import { useAuth } from '../../utils/auth'

export interface Message {
  text: string
  id: string
  createdAt: Date
  author: ConversationMessageAuthorEnum
  isPlaceholder?: boolean
  isBookmarked: boolean
}

interface Props {
  characterId: string
  userName?: string
  shouldRequestLogin?: boolean
  hasBookmark?: boolean
  characterName?: string
  characterAvatarUrl: string | null
  messages: Message[]
  onMessageBookmark: (messageId: string) => void
}

export const ChatMessagesVirtualScroll: React.FC<Props> = ({
  messages,
  userName,
  characterName,
  characterAvatarUrl,
  hasBookmark,
  shouldRequestLogin,
  onMessageBookmark,
}) => {
  const {
    data: { User },
  } = useAuth()
  const virtuosoRef = useRef(null)
  const [atBottom, setAtBottom] = useState(false)
  const showButtonTimeoutRef = useRef<number>()
  const [, setShowButton] = useState(false)

  useEffect(
    () => () => {
      clearTimeout(showButtonTimeoutRef.current)
    },
    []
  )

  useEffect(() => {
    clearTimeout(showButtonTimeoutRef.current)
    if (!atBottom) {
      showButtonTimeoutRef.current = window.setTimeout(() => setShowButton(true), 500)
    } else {
      setShowButton(false)
    }
  }, [atBottom, setShowButton])

  return (
    <Box w="100%" sx={{ flex: 1 }} h="100%">
      {messages.map(({ id, author, createdAt, text, isBookmarked, isPlaceholder }, index) => {
        const isMyMessage = author === ConversationMessageAuthorEnum.User
        return (
          <CommentHtml
            key={id}
            id={id}
            shouldRequestLogin={messages.length - 1 === index && !!shouldRequestLogin}
            isBookmarked={isBookmarked}
            isMyMessage={isMyMessage}
            isLoading={!!isPlaceholder && author === ConversationMessageAuthorEnum.Ai}
            author={{
              image: isMyMessage ? '' : characterAvatarUrl ?? undefined,
              name: isMyMessage ? User.firstName || (userName ?? 'Me') : characterName ?? 'Bot',
            }}
            hasBookmark={hasBookmark}
            postedAt={format(createdAt, 'LLL dd HH:mm')}
            body={text}
            onMessageBookmark={onMessageBookmark}
          />
        )
      })}
    </Box>
  )

  return (
    <Virtuoso
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
      }}
      ref={virtuosoRef}
      initialTopMostItemIndex={999}
      data={messages}
      atBottomThreshold={20}
      atBottomStateChange={(bottom) => {
        setAtBottom(bottom)
      }}
      followOutput={'smooth'}
      itemContent={(index, { id, author, createdAt, text, isBookmarked, isPlaceholder }) => {
        const isMyMessage = author === ConversationMessageAuthorEnum.User
        return (
          <CommentHtml
            key={id}
            id={id}
            shouldRequestLogin={messages.length - 1 === index && !!shouldRequestLogin}
            isBookmarked={isBookmarked}
            isMyMessage={isMyMessage}
            isLoading={!!isPlaceholder && author === ConversationMessageAuthorEnum.Ai}
            author={{
              image: isMyMessage ? '' : characterAvatarUrl ?? undefined,
              name: isMyMessage ? User.firstName || (userName ?? 'Me') : characterName ?? 'Bot',
            }}
            postedAt={format(createdAt, 'LLL dd HH:mm')}
            body={text}
            onMessageBookmark={onMessageBookmark}
          />
        )
      }}
    />
  )
}
