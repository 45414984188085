import { Avatar, Group, Highlight, Stack, Text, Title, createStyles, rem } from '@mantine/core'
import * as React from 'react'

import { GenderEnum } from '../../../generated/graphql'

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily!}`,
  },

  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'center',
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily!}`,
    fontWeight: 900,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(42),
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(18),
    },
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: rem(54),
    paddingLeft: rem(38),
    paddingRight: rem(38),

    [theme.fn.smallerThan('sm')]: {
      height: rem(54),
      paddingLeft: rem(18),
      paddingRight: rem(18),
      flex: 1,
    },
  },
}))

interface Props {
  isSmall?: boolean
  title?: string
  subTitle?: string
  highlight?: string
  character: {
    title: string
    metaData: string
    gender: GenderEnum
    ImageS3File?: { thumbnailSignedUrl?: string | null } | null
  }
}

export function ConversationIntro({ character, title, subTitle, highlight, isSmall }: Props) {
  const { classes } = useStyles()

  const introTitle = title || `Hi I'm ${character.title} your personal assistant.`
  const introSubTitle =
    subTitle || `Start the conversation by sending ${character.title} a message.`
  const introHighlight = highlight
    ? highlight.split(',').map((part) => part.trim())
    : [character.title]

  return (
    <Stack>
      <Group align="flex-start" position="center" noWrap>
        <Avatar
          src={character.ImageS3File?.thumbnailSignedUrl}
          size={isSmall ? 'lg' : 'xl'}
          radius="md"
        />
      </Group>

      <div className={classes.wrapper}>
        <Title order={1} className={classes.title} fz={isSmall ? 'xl' : rem(56)}>
          <Highlight
            highlight={introHighlight}
            highlightStyles={(theme) => ({
              backgroundImage: theme.fn.linearGradient(
                45,
                theme.colors.cyan[5],
                theme.colors.indigo[5]
              ),
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            })}
            lh={1.1}
          >
            {introTitle}
          </Highlight>
        </Title>

        <Text className={classes.description} color="dimmed" fz={isSmall ? 'md' : rem(24)}>
          {introSubTitle.split('\n').map((text) => (
            <Highlight
              key={text}
              display="block"
              highlight={introHighlight}
              highlightStyles={(theme) => ({
                backgroundImage: theme.fn.linearGradient(
                  45,
                  theme.colors.cyan[5],
                  theme.colors.indigo[5]
                ),
                fontWeight: 700,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              })}
              lh={1.4}
            >
              {text}
            </Highlight>
          ))}
        </Text>
      </div>
    </Stack>
  )
}
