import { ActionIcon, Button, Group, Loader, Stack, Textarea, createStyles } from '@mantine/core'
import { IconMicrophone, IconSend } from '@tabler/icons-react'
import React from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const useStyles = createStyles(() => ({
  input: {
    fontSize: '16px',
  },
}))

interface Props {
  initialText?: string
  isLoading: boolean
  isDisabled: boolean
  quickMessages?: React.ReactElement
  onSendMessage: (message: string) => void
}

export const ChatInput: React.FC<Props> = ({
  quickMessages,
  initialText,
  isLoading,
  isDisabled,
  onSendMessage,
}) => {
  const {
    listening,
    resetTranscript,
    finalTranscript,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition()
  const [text, setText] = React.useState('')
  const hasSpeech = browserSupportsSpeechRecognition && !text
  const [isProcessing, setIsProcessing] = React.useState(false)
  const { classes } = useStyles()

  React.useEffect(() => {
    if (listening) {
      setIsProcessing(true)

      return
    }

    const timeout = window.setTimeout(() => {
      if (finalTranscript) {
        onSendMessage(finalTranscript)
        resetTranscript()
      }
      setIsProcessing(false)
    }, 3000)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [listening, finalTranscript, resetTranscript, onSendMessage])

  React.useEffect(() => {
    if (initialText !== undefined) {
      setText(initialText || '')
    }
  }, [initialText])

  return (
    <Stack w="100%" align="stretch" pt="1rem" spacing="xs">
      {quickMessages}
      {listening || isProcessing ? (
        <Button
          loading={!listening}
          leftIcon={<Loader size="sm" variant="bars" color="red" />}
          onClick={() => void SpeechRecognition.stopListening()}
          variant="light"
          color="red"
        >
          {!listening ? 'Processing ...' : 'Stop recording'}
        </Button>
      ) : (
        <Textarea
          autosize
          disabled={isDisabled}
          classNames={classes}
          placeholder="Type your message"
          variant="filled"
          radius="md"
          w="100%"
          maxRows={10}
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          rightSectionWidth={`calc(1.75rem * ${hasSpeech ? 2 : 1} + ${
            hasSpeech ? '0.5rem' : '0rem'
          } + 0.75rem)`}
          rightSection={
            <Group spacing="0.5rem" align="flex-end" h="100%" py="xs">
              {hasSpeech && (
                <ActionIcon
                  disabled={!isMicrophoneAvailable}
                  variant="filled"
                  loading={!listening && isProcessing}
                  onClick={() => {
                    void SpeechRecognition.startListening({ continuous: true })
                  }}
                >
                  <IconMicrophone size="1rem" />
                </ActionIcon>
              )}
              <ActionIcon
                variant="filled"
                disabled={isDisabled}
                loading={isLoading}
                onClick={() => {
                  onSendMessage(text)
                  setText('')
                }}
              >
                <IconSend size="1rem" />
              </ActionIcon>
            </Group>
          }
          onKeyDown={(e) => {
            if (e && e.keyCode == 13 && !e.shiftKey) {
              e.preventDefault()
              if (!isLoading) {
                onSendMessage(text)
                setText('')
              }
            }
          }}
        />
      )}
    </Stack>
  )
}
